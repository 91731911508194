<template>
  <div class="wait" v-if="loading">
    <v-progress-circular indeterminate color="primary" size="60"></v-progress-circular>
  </div>

  <v-alert v-else-if="errors && errors.length > 0" class="mt-4" color="error" text>
    <p class="font-weight-semibold mb-1">Messages</p>
    <p v-for="(error, i) in errors" :key="i" class="text-sm mb-0">
      {{ error }}
    </p>
  </v-alert>

  <div class="user-info" v-else>
    <v-row class="count-reports">
      <v-col sm="6" md="3" cols="12">
        <v-card>
          <v-card-text>
            <div class="cont-count">
              <h3>KW Research Total:</h3>
              <span>{{ kw_count }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="3" cols="12">
        <v-card>
          <v-card-text>
            <div class="cont-count">
              <h3>SERP Total:</h3>
              <span>{{ serp_count }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="3" cols="12">
        <v-card>
          <v-card-text>
            <div class="cont-count">
              <h3>Semantic Total:</h3>
              <span>{{ semantic_count }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="6" md="3" cols="12">
        <v-card>
          <v-card-text>
            <div class="cont-count">
              <h4>Full Reports Total:</h4>
              <span>{{ full_count }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <div class="headers-actions">
      <h2 class="u-title">User Information</h2>
      <v-btn :to="'/account/user/change-password/' + user.id">
        <v-icon>{{ icons.mdiLockOpen }}</v-icon>
        <span>Change Password</span>
      </v-btn>
    </div>
    <v-card>
      <v-card-text>
        <v-form class="multi-col-validation">
          <v-row>
            <v-col cols="12">
              <div class="col-image">
                <v-avatar rounded size="100" class="avatar">
                  <div class="preview-image" @click="chooseFile">
                    <v-img
                      v-if="!uploading"
                      :src="filePreview ? filePreview : images.avatarImg"
                      max-height="100px"
                      max-width="100px"
                    />
                    <v-progress-circular v-else indeterminate color="primary" size="60"></v-progress-circular>
                  </div>
                </v-avatar>

                <input
                  ref="avatarInput"
                  type="file"
                  accept=".jpeg,.png,.jpg,.gif"
                  :hidden="true"
                  @input="selectImgFile"
                  @change="uploadFile"
                />
                <v-btn color="primary" text @click="chooseFile"> Upload file </v-btn>
              </div>
            </v-col>
            <v-col md="6" cols="12">
              <label for="">Company</label>
              <v-chip class="ma-2 px-5">
                {{ account ? account.company_name : '' }}
              </v-chip>
            </v-col>
            <v-col md="6" cols="12">
              <label for="">Email</label>
              <v-chip class="ma-2 px-5">
                {{ user.email }}
              </v-chip>
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="profile.first_name" label="First Name" dense outlined />
            </v-col>
            <v-col md="6" cols="12">
              <v-text-field v-model="profile.last_name" label="Last Name" dense outlined />
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="profile.address" label="Address" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="profile.phone" label="Phone" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="profile.city" label="City" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="profile.country" label="Country" dense outlined></v-text-field>
            </v-col>

            <v-col md="6" cols="12">
              <v-text-field v-model="profile.zip" label="Zip" dense outlined></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-alert v-if="errorsUpdate && errorsUpdate.length > 0" class="mt-4" color="error" text>
                <p class="font-weight-semibold mb-1">Messages</p>
                <p v-for="(error, i) in errorsUpdate" :key="i" class="text-sm mb-0">
                  {{ error }}
                </p>
              </v-alert>
            </v-col>

            <v-col cols="12">
              <v-btn color="primary" class="me-3 mt-4" @click.prevent="updateData">
                <span v-if="saving_user === false">Save changes</span>
                <span v-else>
                  <v-progress-circular indeterminate style="margin-right: 10px"></v-progress-circular>
                  <span>Saving...</span>
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>

    <h2 class="u-title mt-4">Roles</h2>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" v-if="!(roles && !!roles)">
            <span class="text-center">User does not have any roles</span>
          </v-col>
          <v-col cols="12" v-else>
            <label for="">Roles:</label>
            <v-chip class="ma-2" v-for="(role, index) in roles" :key="index">
              {{ role.name }}
            </v-chip>
          </v-col>
          <v-col md="6" cols="12">
            <v-select
              v-model="selected_roles"
              :items="app_roles"
              dense
              multiple
              label="Select role"
              item-text="name"
              item-value="name"
              chips
              outlined
            ></v-select>
          </v-col>

          <v-col cols="12">
            <v-alert v-if="errorsRoles && errorsRoles.length > 0" class="mt-4" color="error" text>
              <p class="font-weight-semibold mb-1">Messages</p>
              <p v-for="(error, i) in errorsRoles" :key="i" class="text-sm mb-0">
                {{ error }}
              </p>
            </v-alert>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" @click.prevent="updateRoles">
              <span v-if="saving_roles === false">Save changes</span>
              <span v-else>
                <v-progress-circular indeterminate style="margin-right: 10px"></v-progress-circular>
                <span>Saving...</span>
              </span>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <dialog-message-size :dialog.sync="dialogSize" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import axios from '@/plugins/axios'
import { required, emailValidator, urlValidator, integerValidator } from '@core/utils/validation'
import avatarImg from '@/assets/images/avatars/1.png'
import DialogMessageSize from '@/views/pages/profile/components/Account/components/DialogMessageSize'
import { mdiLockOpen } from '@mdi/js'

export default {
  components: {
    DialogMessageSize,
  },
  data() {
    return {
      uploading: false,
      loading: true,
      saving_user: false,
      saving_roles: false,
      user: {},
      profile: {},
      account: {},
      roles: [],
      errors: null,
      filePreview: null,
      file: '',
      dialogSize: false,
      errors: [],
      errorsUpdate: [],
      errorsRoles: [],

      app_roles: [],
      selected_roles: [],

      kw_count: 0,
      serp_count: 0,
      semantic_count: 0,
      full_count: 0,

      images: {
        avatarImg,
      },
      icons: {
        mdiLockOpen,
      },
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    ...mapActions({
      getUserDetail: 'client/getUserDetail',
      assignRoles: 'client/assignRoles',
      setSnackbar: 'snackbar/set',
      updateProfile: 'auth/updateProfile',
      uploadAvatar: 'profile/uploadAvatar',
    }),
    chooseFile() {
      this.$refs.avatarInput.click()
    },
    async getData() {
      this.loading = true
      await this.getUserDetail(this.$route.params.user_id)
        .then(resp => {
          if (resp && resp.data) {
            if (resp.data.user) {
              this.user = { ...resp.data.user }
            }
            if (resp.data.profile) {
              this.profile = { ...resp.data.profile }
              if (this.profile.avatar) {
                this.filePreview = this.profile.avatar
              }
            }
            if (resp.data.profile) {
              this.account = { ...resp.data.account }
            }
            if (resp.data.roles) {
              this.roles = [...resp.data.roles]
              this.selected_roles = resp.data.roles.map(r => r.name)
            }
            if (resp.data.app_roles) {
              this.app_roles = [...resp.data.app_roles]
            }
            this.kw_count = resp.data.kw_count
            this.serp_count = resp.data.serp_count
            this.semantic_count = resp.data.semantic_count
            this.full_count = resp.data.full_count
          }
        })
        .catch(error => {
          console.log(error)
          this.errors = error.response.data.errors
        })
        .finally(() => {
          this.loading = false
        })
    },
    async updateData() {
      this.saving_user = true
      const data = {
        profile: this.profile,
      }
      await this.updateProfile(data)
        .then(response => {
          if (response && response.data) {
            this.setSnackbar({
              status: true,
              text: 'Successfully saved data.',
              color: 'primary',
            })
            this.errorsUpdate = null
          }
        })
        .catch(error => {
          console.log(error.response)
          this.errorsUpdate = error.response.data.errors

          this.setSnackbar({
            status: true,
            text: 'Data could not be saved.',
            color: 'error',
          })
        })
        .finally(() => {
          this.saving_user = false
        })
    },
    selectImgFile(e) {
      let avatarInput = this.$refs.avatarInput
      let imgFile = avatarInput.files

      let sizeMB = avatarInput.files[0].size / 1048576 // 1024 * 1024
      if (sizeMB >= 2) {
        this.dialogSize = true
      } else {
        this.file = avatarInput.files[0]
        if (imgFile && imgFile[0]) {
          let reader = new FileReader()
          reader.onload = e => {
            this.filePreview = e.target.result
          }
          reader.readAsDataURL(imgFile[0])
          this.$emit('avatarInput', imgFile[0])
        }
      }
    },
    async uploadFile() {
      let avatarInput = this.$refs.avatarInput
      let sizeMB = avatarInput.files[0].size / 1048576 // 1024 * 1024

      if (sizeMB <= 2) {
        const formData = new FormData()
        formData.append('avatar', this.file)
        const headers = { 'Content-Type': 'multipart/form-data' }

        const onUploadProgress = event => {
          this.percentage = Math.round((100 * event.loaded) / event.total)
        }

        this.uploading = true
        await axios
          .post(`/user/avatar`, formData, { headers }, onUploadProgress)
          .then(response => {
            this.profile.avatar = response.data.file_path
          })
          .catch(error => {
            console.log(error.response)
            this.errorsUpdate = error.response.data.errors
          })
          .finally(() => {
            this.uploading = false
            this.percentage = 0
          })
      }
    },
    async updateRoles() {
      this.saving_roles = true
      const data = {
        user_id: this.user.id,
        roles: this.selected_roles,
      }
      await this.assignRoles(data)
        .then(response => {
          if (response && response.data) {
            this.getData()
            this.setSnackbar({
              status: true,
              text: 'Successfully saved data.',
              color: 'primary',
            })
          }
        })
        .catch(error => {
          console.log(error.response)
          this.errorsRoles = error.response.data.errors

          this.setSnackbar({
            status: true,
            text: 'Data could not be saved.',
            color: 'error',
          })
        })
        .finally(() => {
          this.saving_roles = false
        })
    },
  },
}
</script>

<style lang="css" scoped>
.u-title {
  margin: 10px 0px;
}
.cont-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.count-reports {
  margin-bottom: 10px;
}
.wait {
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}
.col-image {
  max-width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
}
.preview-image {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
}
.user-info {
  display: flex;
  flex-direction: column;
}
.text-center {
  text-align: center;
}

.headers-actions {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>

